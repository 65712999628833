// import { THEME_ENUM } from 'constants/theme.constant'



// const userDetailsJSON = localStorage.getItem("userDetails");
// const userDetails = JSON.parse(userDetailsJSON);
// const userRoles = userDetails?.userRoles[0];
// let themeConfig;
// if (userRoles === 'Admin' || userRoles === 'Company Admin') {
//     console.log('Admin or Company Admin detected. Using first theme config.');
//     themeConfig = {
//         themeColor: 'indigo',
//         direction: THEME_ENUM.DIR_LTR,
//         mode: THEME_ENUM.MODE_LIGHT,
//         locale: 'en',
//         primaryColorLevel: 600,
//         cardBordered: true,
//         panelExpand: false,
//         controlSize: 'md',
//         navMode: THEME_ENUM.NAV_MODE_LIGHT,
//         layout: {
//             type: THEME_ENUM.LAYOUT_TYPE_STACKED_SIDE,
//             sideNavCollapse: false,
//         },
//     };
// } else {
//     console.log('Other role detected. Using second theme config.');
//     themeConfig = {
//         themeColor: 'indigo',
//         direction: THEME_ENUM.DIR_LTR,
//         mode: THEME_ENUM.MODE_LIGHT,
//         locale: 'en',
//         primaryColorLevel: 600,
//         cardBordered: true,
//         panelExpand: false,
//         controlSize: 'md',
//         navMode: THEME_ENUM.NAV_MODE_LIGHT,
//         layout: {
//             type: THEME_ENUM.LAYOUT_TYPE_MODERN,
//             sideNavCollapse: false,
//         },
//     };
// }

// export { themeConfig };




import { THEME_ENUM } from "constants/theme.constant";

/**
 * Since some configurations need to be match with specific themes,
 * we recommend to use the configuration that generated from demo.
 */

// export const themeConfig = {
//     themeColor: 'indigo',
//     direction: THEME_ENUM.DIR_LTR,
//     mode: THEME_ENUM.MODE_LIGHT,
//     locale: 'en',
//     primaryColorLevel: 600,
//     cardBordered: true,
//     panelExpand: false,
//     controlSize: 'md',
//     navMode: THEME_ENUM.NAV_MODE_LIGHT,
//     layout: {
//         type: THEME_ENUM.LAYOUT_TYPE_MODERN,
//         sideNavCollapse: false,
//     },
// }
// const userDetailsJSON = localStorage.getItem("userDetails");
//   const userDetails = JSON.parse(userDetailsJSON);
// const userRoles = userDetails.userRoles

// export const themeConfig = {
//     themeColor: 'indigo',
//     direction: THEME_ENUM.DIR_LTR,
//     mode: THEME_ENUM.MODE_LIGHT,
//     locale: 'en',
//     primaryColorLevel: 600,
//     cardBordered: true,
//     panelExpand: false,
//     controlSize: 'md',
//     navMode: THEME_ENUM.NAV_MODE_LIGHT,
//     layout: {
//         type: THEME_ENUM.LAYOUT_TYPE_STACKED_SIDE,
//         sideNavCollapse: false,
//     },
// }
const notAllowedRoutes = [
  
  "https://app.complianceguru.co.in/Availability",
  "https://app.complianceguru.co.in/CustomerServiceInfo",
  "https://app.complianceguru.co.in/CancelMeeting",
  "https://app.complianceguru.co.in/ReScheduleMeeting",
  "https://app.complianceguru.co.in/ActivateAccount",
  "https://app.complianceguru.co.in/reset-password",
  "https://app.complianceguru.co.in/login",
  "http://localhost:3000/CustomerServiceInfo",
  "http://localhost:3000/Availability",
  "http://localhost:3000/CancelMeeting",
  "http://localhost:3000/ReScheduleMeeting",
  "http://localhost:3000/ActivateAccount",
  "http://localhost:3000/reset-password",
  "http://localhost:3000/login",
];

const userDetailsJSON = localStorage.getItem("userDetails");
const userDetails = JSON.parse(userDetailsJSON);
const userRoles = userDetails?.userRoles[0];
let themeConfig;
if (userRoles === "Admin" || userRoles === "Company Admin") {
  const isBlank = notAllowedRoutes.some((url) =>
    window.location.href.includes(url)
  );
  if (isBlank) {
   themeConfig = {
      themeColor: "indigo",
      direction: THEME_ENUM.DIR_LTR,
      mode: THEME_ENUM.MODE_LIGHT,
      locale: "en",
      primaryColorLevel: 600,
      cardBordered: true,
      panelExpand: false,
      controlSize: "md",
      navMode: THEME_ENUM.NAV_MODE_LIGHT,
      layout: {
        type: THEME_ENUM.LAYOUT_TYPE_BLANK,
        sideNavCollapse: false,
      },
    };
  } else {
    
    themeConfig = {
      themeColor: "indigo",
      direction: THEME_ENUM.DIR_LTR,
      mode: THEME_ENUM.MODE_LIGHT,
      locale: "en",
      primaryColorLevel: 600,
      cardBordered: true,
      panelExpand: false,
      controlSize: "md",
      navMode: THEME_ENUM.NAV_MODE_LIGHT,
      layout: {
        type: THEME_ENUM.LAYOUT_TYPE_STACKED_SIDE,
        sideNavCollapse: false,
      },
    };
  }
} else {
   const isBlank = notAllowedRoutes.some((url) =>
     window.location.href.includes(url)
   );
  
  if (isBlank) {
      themeConfig = {
      themeColor: "indigo",
      direction: THEME_ENUM.DIR_LTR,
      mode: THEME_ENUM.MODE_LIGHT,
      locale: "en",
      primaryColorLevel: 600,
      cardBordered: true,
      panelExpand: false,
      controlSize: "md",
      navMode: THEME_ENUM.NAV_MODE_LIGHT,
      layout: {
        type: THEME_ENUM.LAYOUT_TYPE_BLANK,
        sideNavCollapse: false,
      },
    };
  } else {
   
    themeConfig = {
      themeColor: "indigo",
      direction: THEME_ENUM.DIR_LTR,
      mode: THEME_ENUM.MODE_LIGHT,
      locale: "en",
      primaryColorLevel: 600,
      cardBordered: true,
      panelExpand: false,
      controlSize: "md",
      navMode: THEME_ENUM.NAV_MODE_LIGHT,
      layout: {
        type: THEME_ENUM.LAYOUT_TYPE_MODERN,
        sideNavCollapse: false,
      },
    };
  }
}

export { themeConfig };
