import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: "",
  userRoles: [],
  userData: "",
};

export const userSlice = createSlice({
  name: "auth/profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
     
      const { user, userRoles, userData } = action.payload;
    
      return {
        ...state,
        user: user || [],
        userRoles: userRoles || [],
        userData: userData || [],
      };
    },
    userLoggedOut: () => initialState,
  },
});

export const { setProfile } = userSlice.actions;

export default userSlice.reducer;
