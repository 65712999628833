const appConfig = {
    //apiPrefix: 'https://api.credence.zontecsolutions.com/api/Credence/',
    //  apiPrefix: 'http://local.complianceguruApi.com/api/',
    // apFilePrefix: 'http://local.complianceguruApi.com/',
    apiPrefix: 'https://api.complianceguru.co.in/api/',
    apFilePrefix: 'https://api.complianceguru.co.in/',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    enableMock: false
}

export default appConfig

