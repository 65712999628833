

import BaseService from "./BaseService";
import { toast, Notification } from "components/ui";

const ApiService = {
  fetchData(param) {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then((response) => {
         
          resolve(response);
          if (response?.data?.message  || response?.data?.response) {
            toast.push(
              <Notification
                title={"Successfuly Done"}
                type="success"
                duration={2500}
              >
                  {response.data.message || response?.data?.response}
              </Notification>,
              {
                placement: "top-center",
              }
            );
          }
        })
        .catch((errors) => {
         

          if (errors.response.data?.message || errors.response.data?.response  ) {
            toast.push(
              <Notification title={"Failed"} type="danger" duration={2500}>
                {errors.response.data?.message || errors.response.data?.response  }
              </Notification>,
              {
                placement: "top-center",
              }
            );
          }
          //reject(errors)
        });
    });
  },
};




export default ApiService;
