import ApiService from "./ApiService";
import ApiService2 from "./ApiService2";
export async function apiSignIn(data) {
  return ApiService2.fetchData({
    url: "Account/login",
    method: "post",
    mode: "no-cors",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "Account/register/",
    method: "post",
    mode: "no-cors",
    data,
  });
}
export async function changepassword(data) {

  return ApiService.fetchData({
    url: "Account/ChangePassword",
    method: "post",
    data,
  });
}

export async function apigetUserDetails(id) {
  return ApiService.fetchData({
    url: `Account/GetUserDetails?Id=${id}`,
    method: "get",
    mode: "no-cors",
   
  });
}

export async function apiForgotPassword(data) {
  return ApiService2.fetchData({
    url: "Account/ForgotPassword",
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
 return ApiService.fetchData({
    url: `Account/ResetPassword?token=${data.token}`,
    method: "post",
    data,
  });
}

export async function apiActivateAccount(params) {
 return ApiService.fetchData({
    url: `Account/ActivateAccount`,
    method: "post",
    params,
  });
}
