import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  apiSignIn,
  apiSignOut,
  apiSignUp,
  apigetUserDetails,
} from "services/AuthService";
import { toast, Notification } from 'components/ui'
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate,useLocation  } from "react-router-dom";
import useQuery from "./useQuery";


function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data) {
     const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        localStorage.setItem("access_token", token);
       if (
          resp.status === 200 &&
          resp.data.status !== "failed" &&
          resp.data.status !== "Failed"
        ) {
          const dataSignIn = {
            token: token,
            signedIn: true,
          };

          const getUserData = async () => {
            try {
              const response = await apigetUserDetails(resp.data.userId);
            if (response.data && response.status === 200) {
                localStorage.setItem(
                  "userDetails",
                  JSON.stringify(response.data)
                );
                const redirectUrl = query.get(REDIRECT_URL_KEY);
                navigate(
                  redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                );
                if (resp.status === 200 && resp.data.status !== "failed" && resp.data.status !== "Failed") {
                  // Handle successful sign-in
                  toast.push(
                    <Notification title={"Sign In Successful"} type="success" duration={4500}>
                      You have successfully signed in.
                    </Notification>,
                    { placement: "top-center" }
                  );
                }
                // Reload window after successful sign-in
                window.location.reload();
              }
            } catch (errors) {

              return {

                status: "failed",
                message: errors?.response?.data?.message || errors.toString(),
                errors: errors?.response?.data?.status,
              };
            }
          };
          await getUserData();
        } else {

          signOut();
          navigate("/sign-in");

        }
      }
    } catch (error) {
      if (error?.response?.status !== 200) {
        signOut();
        navigate("/sign-in");
      }
      let _error_res = "";
      if (error.response) {

        if (error.response.data.message) {
          _error_res = error.response.data.message;
        }
        else {
          _error_res = error.response.data.response;
        }


      }
      else {
        _error_res = error?.response?.data?.message || error.toString();
      }

      return {
        status: "failed",
        message: _error_res,
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);

      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",

        };

      } else {
        return {
          status: "failed",
          message: resp?.message,
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message:
          errors?.response?.data?.message ||
          "Something went wrong, Please try again letter.",
      };
    }
  };

  
    const handleSignOut = () => {
     localStorage.removeItem("userDetails");
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    // await apiSignOut()
    handleSignOut();
  };
  
return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
    signOut: handleSignOut,
  }
  };


export default useAuth;
